<template>
  <v-main class="container-prosker">
    <v-row class="title_highlight_color border-radius-medium align-items-stretch mb-5 mt-5">
      <v-col cols="12" sm="6" >
        <div :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/Vector_9.png')})`,
    backgroundPosition: 'left 0px top 0px'
  }"  >
        <img :src="i18nImage('home_banner_profiles.png')" class="img-fluid"/>
      </div>
      </v-col>
      <v-col cols="12" sm="6" :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/Vector_7.png')})`,
    backgroundPosition: 'left 0px bottom'
  }">
    <div class="d-flex flex-column justify-content-center h-100">
        <h1 class="text-h4 text-md-h3 secondary--text font-weight-bold text-center text-md-left text-white">{{ $t('proskerBannerTitle') }}</h1>
        <p class="font-size-16 text-center text-md-left mt-2">{{ $t('freeTrial30') }}</p>
        <div class="d-flex justify-content-center justify-content-md-start">
          <general-button :action="goToSignUp" :loading="false" :message="$t('startNow')" size="medium" cssClasses="mt-4"/>
        </div>
      </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6" class="d-flex flex-column p-5 p-md-4">
        <h1 class="text-h4 text-md-h3 secondary--text font-weight-bold mb-5">{{$t('subscriptionText1')}}</h1>
        <topic :title="$t('boostBusiness')" :description="$t('increaseVisibility')" :imageUrl="require('@/assets/images/page-img/startup--shop-rocket-launch-startup.svg')" />
        <topic :title="$t('optimize')"  :description="$t('forgetPendencies')" :imageUrl="require('@/assets/images/page-img/optimiza-trabajo.svg')" />
        <topic :title="$t('enjoyTheTools')" :description="$t('automateProcess')" :imageUrl="require('@/assets/images/page-img/herramientas.svg')" />
        <div class="text-center text-md-left">
          <general-button :action="goToSignUp" :loading="false" :message="$t('startNow')" size="medium" cssClasses="mt-4"/>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="p-md-4" >
        <div class="d-flex justify-content-center">
            <img :src="i18nImage('subscription_topics_bannes.png')" class="img-fluid">
        </div>
      </v-col>
    </v-row>
    <v-row :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/Vector_8.png')})`,
    backgroundPosition: 'left 0px bottom'
  }"
  class="mt-5"
  >
      <v-col cols="12" md="6" class="p-md-4">
        <h1 class="text-h4 text-md-h3 secondary--text font-weight-bold text-center text-md-left">{{ $t('digitalOffice') }}</h1>
      </v-col>
      <v-col cols="12" md="6" class="mb-5">
        <p class="text-center text-md-left font-size-16 mt-4" v-html="$t('dedicate_love')"/>
        <div class="d-none d-md-block">
          <general-button :action="goToSignUp" :loading="false" :message="$t('wantToSignUp')" size="medium" cssClasses="mt-4" secondary/>
        </div>
      </v-col>
      <v-col col="12" md="4"  v-for="(item, n) in cardsAdvantages" :key="n" v-show="!$vuetify.breakpoint.mobile" >
          <advantages-card :description="item.text" :title="item.title"  :imageUrl="require(`@/assets/images/page-img/${item.icon}`)" maxWith="100%"/>
      </v-col>
      <v-col cols="12" class="text-center">
        <hooper v-show="$vuetify.breakpoint.mobile" :centerMode="true"  class="h-100" :settings='hooperSettings' >
          <slide  v-for="(item, n) in cardsAdvantages" :key="n">
              <advantages-card :description="item.text" :title="item.title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${item.icon}`)"/>
            </slide>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </v-col>
    </v-row>
    <v-row class="background_contrast border-radius-medium p-4 mt-16" :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/Vector_11.png')})`,
    backgroundPosition: 'left 0px bottom'
  }">
      <v-col col="12" sm="12" md="6">
        <h2 class="secondary-prosk font-weight-bold text-left mt-16 w-100">{{ $t('reachMoreBuyers') }}</h2>
        <p class="text-left mb-8 secondary--text w-50">{{ $t('potentiate') }}</p>
      </v-col>
      <v-col col="12" sm="12"  md="6">
        <v-card class="border-radius-medium p-5 d-flex flex-column justify-content-between" min-height="500">
          <topic :title="$t('boostBusiness')" :description="$t('increaseVisibility')" :imageUrl="require('@/assets/images/page-img/startup--shop-rocket-launch-startup.svg')" />
          <topic :title="$t('optimize')"  :description="$t('forgetPendencies')" :imageUrl="require('@/assets/images/page-img/optimiza-trabajo.svg')" />
          <topic :title="$t('enjoyTheTools')" :description="$t('automateProcess')" :imageUrl="require('@/assets/images/page-img/herramientas.svg')" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-row>
        <v-col col="12">
          <h2 class="secondary-prosk font-weight-bold text-center mt-16">{{ $t('nextLevel') }}</h2>
          <p class="text-center main_text_color--text mb-8">
            {{ $t('subscribeAndGrowYourBusiness') }}
          </p>
        </v-col>
      </v-row>
      <v-container>
          <div :class="$vuetify.breakpoint.mobile ? 'mx-8' : 'mx-16'">
              <v-row v-if="loading" class="mx-16">
                  <v-col v-for="index in 2" :key="index" cols="6">
                      <v-skeleton-loader
                          v-bind="skeletonAttrs"
                          type="article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line,
                           list-item-two-line, actions"
                      ></v-skeleton-loader>
                  </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  class="pa- d-flex align-stretch"
                  cols="12"
                  md="6"
                  v-for="(item, i) in this.subscriptionPlans"
                  :key="i"
                >
                      <v-card
                        class="pricing-card pt-3 pb-5 px-5 border-radius-medium w-100"
                        :class="{
                          'title_highlight_color': item.popular,
                          // 'ml-0 ml-md-5 mt-5 mt-md-0': !item.popular,
                        }"
                        :elevation='item.popular ? 0 : 2'
                      >
                          <v-card-text>
                              <h4 class="text-center font-weight-bold text-h4" :class="{
                                'text-white': item.popular,
                                'main_text_color--text': !item.popular
                              }">{{ item.name }}</h4>
                              <h5 v-if="item.name === 'Premium'" class="text-overline text-center text-white">( {{ $t('freeTrial') }} ) </h5>
                              <h5 v-if="item.name !== 'Premium'" class="text-overline text-center">{{ $t('simple_start') }}</h5>
                              <div class="button text-center w-100">
                                <div class="d-flex align-center w-100 justify-content-center mb-4 ">
                                  <span
                                    class="display-1 font-weight-bold"
                                    :class="{
                                      'text-white': item.popular,
                                      'main_text_color--text': !item.popular
                                    }"
                                  >
                                      <money-format
                                        :value="item.cost"
                                        :locale="$i18n.locale"
                                        :currency-code="currencyCode"
                                        :supplemental-precision="0"
                                        :hide-subunits="true"
                                      />
                                    </span>
                                    <span class="font-weight-bold" :class="{
                                'text-white': item.popular,
                                'main_text_color--text': !item.popular
                              }">/{{ $t("month") }}</span>
                                </div>
                                <div class="text-center">
                                    <general-button
                                      v-if="$options.SUBSCRIPTIONS_ENABLED"
                                      :action="sendEmailOrLogin"
                                      :loading="false"
                                      :message="$t('startCTA')"
                                      size="medium"
                                      cssClasses="mb-2 w-100 secondary--text"
                                      :white="!!item.popular"
                                    />
                                </div>
                              </div>
                              <v-divider></v-divider>
                              <h4 class="text-left font-weight-bold " :class="{
                                'text-white': item.popular,
                                'main_text_color--text': !item.popular
                              }">{{ $t('includes') }}</h4>
                              <v-col class="p-0" offset="0" offset-sm="1" cols="12" sm="10" v-if="item.popular">
                                  <v-list-item class="p-0" v-for="(item, i) in itemsPremium" :key="i">
                                      <img src='@/assets/images/page-img/Check-green-purple.svg'/>
                                      <v-list-item-content>
                                          <v-list-item-title class="mt-1 ml-2 space-wrap text-white">{{ $t(item.text) }}</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-col>
                              <v-col class="p-0 align-content-center" offset="0" offset-sm="1" cols="12" sm="10" v-else>
                                  <v-list-item v-for="(item, i) in items" :key="i">
                                      <img src='@/assets/images/page-img/Check-green-black.svg'/>
                                      <v-list-item-content>
                                          <v-list-item-title class="mt-1 ml-2 space-wrap main_text_color--text" >{{ $t(item.text) }}</v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-col>
                          </v-card-text>
                      </v-card>
              </v-col>
              </v-row>
          </div>
        </v-container>
    </v-row>
    <faq-section />
  </v-main>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import MoneyFormat from 'vue-money-format';
import CryptoJS from 'crypto-js';
import { FEATURE_SUBSCRIPTIONS, AES_SECRET } from '@/misc/constants';
import { isFeatureEnabled } from '@/misc/featureFlagService';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import Topic from '@/components/socialvue/topics/Topic.vue';
import AdvantagesCard from '../../components/socialvue/cards/AdvantagesCard.vue';
import FaqSection from '@/components/socialvue/faqSection/FaqSection.vue';
import { Hooper, Slide,
  Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';
import i18ImagesMixin from '@/mixins/i18ImagesMixin';

export default {
  name: 'SubscriptionPage',
  components: {
    MoneyFormat,
    GeneralButton,
    Topic,
    AdvantagesCard,
    Hooper,
    Slide,
    HooperPagination,
    FaqSection
  },
  mixins: [i18ImagesMixin],
  data () {
    return {
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true,
      currencyCode: '',
      video: {
        url: 'https://www.youtube.com/embed/825x5HPM730?si=t9ryP7QEoYvahgSX'
      },
      itemsCard: [
        {
          step: 1,
          text: 'createYourProskerProfile'
        },
        {
          step: 2,
          text: 'showWhatYouDo'
        },
        {
          step: 3,
          text: 'communicateWithClients'
        }
      ],
      activeTab: null,
      subscriptionInformation: {},
      itemsPremium: [
        {
          text: 'publicProfile',
          icon: 'mdi-checkbox-marked-circle',
          included: true
        },
        {
          text: 'customAccount',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'electronicalCharge',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        // {
        //   text: 'premiumFee',
        //   icon: 'mdi-check-circle-outline',
        //   included: true
        // },
        {
          text: 'virtualDiary',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'positioning',
          icon: 'mdi-check-circle-outline',
          included: true
        }
      ],
      items: [
        {
          text: 'publicProfile',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        {
          text: 'internalPublications',
          icon: 'mdi-check-circle-outline',
          included: true
        },
        { text: 'Chat', icon: 'mdi-check-circle-outline', included: true },
        {
          text: 'electronicalCharge',
          icon: 'mdi-check-circle-outline',
          included: true
        }
        // {
        //   text: 'preferencialFee',
        //   icon: 'mdi-check-circle-outline',
        //   included: true
        // }

      ],
      faqs: [
        {
          question: 'whatIsPROSK',
          answer: 'whatIsPROSKAnswer'
        },
        {
          question: 'howDoIRegisterInPROSK',
          answer: 'createYourUsernamePasswordCompletingForm',
          link: 'auth1.sign-up1',
          answer2: 'thenCompleteYouProfile'
        },
        {
          question: 'isMyPROSKProfileUnique',
          answer: 'yourPROSKProfileIsUnique',
          answer2: 'whereSelectAllCategories'
        },
        {
          question: 'howCanIHaveGoodReputation',
          answer: 'TrustIsEssentialDependsOnQuality'
        },
        {
          question: 'howCanIMakeAttractiveProfile',
          answer: 'takingCareOfImage',
          answer2: 'takingCareOfImage2'
        }
        // {
        //   question: 'howDoUsersContactMe',
        //   answer: 'usersRegisteredInPROSKWillSendYouMessage'
        // },
        // {
        //   question: 'howDoesPROSKNotifyMe',
        //   answer: 'currentlyFromProfileWillHaveNotifications'
        // },
        // {
        //   question: 'canIChargeServices',
        //   answer: 'soonPROSKWillHaveServiceAcceptPayments'
        // },
        // {
        //   question: 'whatDoINeedToCollectServices',
        //   answer: 'whatDoINeedToCollectServicesAnswer'
        // },
        // {
        //   question: 'canISchedule',
        //   answer: 'soonPROSKWillHaveDigitalAgenda',
        //   answer2: 'soonPROSKWillHaveDigitalAgenda2'
        // },
        // {
        //   question: 'doesPROSKChargeCommission',
        //   answer: 'noThatDependsHowYouUsePlatform'
        // },
        // {
        //   question: 'doIHaveToPaySubscription',
        //   answer: 'itDependsOnYou',
        //   answer2: 'itDependsOnYou2',
        //   answer3: 'itDependsOnYou3'
        // },
        // { question: 'isThereTrialPeriod', answer: 'yesInAllCasesOffer30Days' },
        // {
        //   question: 'howDoIReceivePayment',
        //   answer: 'yourMercadoPagoAccount'
        // },
        // {
        //   question: 'howLongShouldIWaitCollectSales',
        //   answer: 'chargeDelay'
        // },
        // {
        //   question: 'doIHaveToIssueInvoices',
        //   answer: 'serviceYouProvideYouAreResponsible'
        // },
        // {
        //   question: 'ifIGetJobThroughAmICovered',
        //   answer: 'youAreResponsibleComplyingObligations'
        // },
        // {
        //   question: 'dataProtected',
        //   answer: 'dataProtectedAnswer',
        //   answer2: 'dataProtectedAnswer2'
        // }
      ],
      subscriptionType: {},
      subscriptionPlans: [],
      cardsAdvantages: [
        {
          icon: 'calendar-add--add-calendar-date-day-month.svg',
          title: this.$t('bringWithYou'),
          text: this.$t('manageFromWherever')
        },
        {
          icon: 'dollar-coin-1--accounting-billing-payment-cash-coin-currency-money-finance.svg',
          title: this.$t('acceptPayment'),
          text: this.$t('acceptPaymentAnswer')
        },
        {
          icon: 'chat-bubble-oval-notification--messages-message-bubble-chat-oval-notify-ping.svg',
          title: this.$t('comunication'),
          text: this.$t('comunicationSubtext')
        }
      ],
      hooperSettings: {
                    infiniteScroll: true,
                    centerMode: true,
                    autoPlay: true,
                    playSpeed: 3500,
                    breakpoints: {
                        2400: {
                            itemsToShow: 4
                        },
                        1800: {
                            itemsToShow: 4
                        },
                        1500: {
                            itemsToShow: 3
                        },
                        900: {
                            itemsToShow: 3
                        },
                       500: {
                            itemsToShow: 2
                        },
                        0: {
                            itemsToShow: 1
                        }
                    }
                }
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.currencyCode = process.env.VUE_APP_LOCATION.includes('br') ? 'BRL' : 'UYU';
    this.setImportedConstants();
    this.getSubscriptionPlans();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    ...mapActions({
      updateSignUpUserType: 'auth/updateSignUpUserType'
    }),
    goToSignUp () {
      this.updateSignUpUserType(this.$options.USER_TYPE_CLIENT);
      this.$router.push({ name: 'auth1.sign-up1' });
    },
    setImportedConstants () {
      this.$options.SUBSCRIPTIONS_ENABLED = isFeatureEnabled(process.env.VUE_APP_RELEASE_VERSION, FEATURE_SUBSCRIPTIONS);
      this.$options.AES_SECRET = AES_SECRET;
    },
    sendEmailOrLogin () {
      if (!this.currentUser) {
        this.$router.push({ name: 'auth1.sign-in1' });
      } else {
        const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions/contact`;
        const data = {
          user: this.currentUser
        };
        axios.post(url, data).then(() => {
          this.$swal.fire({
            title: this.$t('awesome'),
            text: this.$t('queryReceived'),
            confirmButtonColor: '#19AD93'
          });
        });
      }
    },
    sendToRegisterFreePlan () {
      this.subscriptionType.free = true;
      this.$router.push({
        name: 'auth1.sign-up1',
        params: { query: JSON.stringify(this.subscriptionType) }
      });
    },
    sendToCheckoutPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = true;
      this.$router.push({
        name: 'subscription-checkout',
        params: { subscriptionId: CryptoJS.AES.encrypt(subscription.id.toString(), this.$options.AES_SECRET).toString() }
      });
    },
    sendToCheckoutNotLoggedPremiumPlan (subscription) {
      this.subscriptionInformation = subscription;
      this.subscriptionInformation.isSubscriptionType = true;
      this.subscriptionInformation.isUserLogged = false;
      this.$router.push({
        name: 'subscription-checkout',
        params: { subscriptionId: CryptoJS.AES.encrypt(subscription.id.toString(), this.$options.AES_SECRET).toString() }
      });
    },
    isPremiumUser (user) {
      return user.active_subscription.find(item => item.name === 'Premium');
    },
    beginOnProsk (subscription) {
      if (!this.currentUser) {
        this.sendToRegisterFreePlan();
      } else if (this.currentUser && subscription.id === 2) {
        this.sendToCheckoutPremiumPlan(subscription);
      }
    },
    getSubscriptionPlans () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/subscriptions`;
      this.loading = true;
      axios.get(url).then((response) => {
        this.subscriptionPlans = response.data.data.filter(item => item.name !== 'Free Trial').reverse();
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
.pricing-card {
  max-width: 350px;
  margin: auto;
}

.button-absolute {
  position: absolute;
  bottom: 60px;
  right: 400px;
}

.button-absolute-mobile {
  position: absolute;
  bottom: 10px;
  right: 0px;
  display: none;
}

.button-absolute-mobile > button {
 height: 30px !important;
}

.info-card {
  border-radius: 2rem;
  margin: 24px 36px;
  width: calc(100%-72px);
  background-color: #f1f1f1;
  color: #3F44A6;
  box-shadow: 12px 10px 5px rgba(0, 0, 0, .1);
}

.width-50 {
  width: 50%;
  margin: 12px 20px;
}

.width-90 {
  width: 90%;
  padding-left: 10%;
}

.sized-div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}

.space-wrap {
  white-space: pre-wrap;
}

.custom-radio {
  border-radius: 100%;
  height: 180px;
  width: 160px;
  padding: 24px;
  box-shadow: 4px 2px 2px rgba(0, 0, 0, .1);
  margin-bottom: 12px;
}

.card {
  height: 620px;
  position: relative;
}

.card-mobile {
  height: 620px;
  position: relative;
}

.mobile-subscription {
  position: absolute;
  width: 80%;
  bottom: 0px;
  left: -18%;
}

.line-separator {
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media (max-width: 1264px) {
  .width-50 {
    width: 100%;
  }
}

</style>
