<template>
    <div>
        <div class="d-flex align-items-start mt-3">
            <img class="mr-2" :src='imageUrl' width="28"/>
            <div>
                <p class="text-left font-size-16 secondary--text font-weight-bold m-0">{{title}}</p>
                <p class="text-left font-size-16 ">{{description}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Topic',
    props: ['imageUrl', 'title', 'description']
};
</script>
